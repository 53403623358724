import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components';

export default class RegistrationRequestForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };

  state = {
    reason: '',
    validationError: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.reason === '') {
      this.setState({ validationError: 'Registration reason must not be blank.' });
    } else {
      this.props.onSubmit({ reason: this.state.reason });
      this.setState({ validationError: '' });
    }
  };

  render() {
    const placeholder = ` Please provide the reason for registration. Example reasonings:
      - Fits POW/PD Plan
      - Focuses on an area you seek to improve
      - Personal interest in the topic
      - Best match given constraints of schedule

    This rationale helps focus your selection of events and allows your
    manager to make the most informed decision during the approval
    process.
    `;
    return (
      <form>
        {this.state.validationError !== '' ? (
          <Alert type="danger">
            <strong style={{ color: 'inherit' }}>Error</strong> {this.state.validationError}
          </Alert>
        ) : null}
        <div className="form-group">
          <label htmlFor="reasonInput">Registration Reason</label>
          <textarea
            className="form-control"
            id="reasonInput"
            rows="4"
            cols="10"
            placeholder={placeholder}
            onChange={(e) => this.setState({ reason: e.target.value })}
          />
        </div>
        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
          <i className="fa fa-plus icon-space-r" />
          Submit
        </button>{' '}
        <button type="button" className="btn btn-default" onClick={this.props.close}>
          Cancel
        </button>
      </form>
    );
  }
}

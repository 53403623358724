import React from 'react';

import { StrandPanelHeading } from 'components/shared';
import { creditApprovalPropType } from '../proptypes';
import { EventDictionary } from 'helpers/dictionaries';
import { eventExplorePropTypes } from 'helpers/proptypes';
import {
  NtidSponsorMessage,
  ReasonableAccommodationsStatement,
  RitNonDiscriminationStatement,
} from 'components';

const { CREDIT_TYPES } = EventDictionary;

const EventCreditTypeLabel = (props) => {
  if (props.credit === CREDIT_TYPES.NO_CREDIT) {
    return (
      <p>
        <em>
          <strong>This is a non-credit event.</strong>
        </em>
      </p>
    );
  }
  if (props.credit === CREDIT_TYPES.ACET_CREDIT) {
    return (
      <div>
        <ul className="list-unstyled">
          <li className="img-link">
            <div className="card">
              <a href="https://www.rid.org/continuing-education/acet/" target="_blank">
                <img
                  src="/static/images/ACET.png"
                  alt="ACET Sponsored Credit"
                  className="img-responsive"
                />
              </a>
            </div>
          </li>
        </ul>
        <p>
          <em>
            <strong>This event is approved for ACET credit only</strong>
          </em>
        </p>
      </div>
    );
  }
  if (props.credit === CREDIT_TYPES.RID_CREDIT) {
    return (
      <div>
        <ul className="list-unstyled">
          <li className="img-link">
            <div className="card">
              <a href="https://www.rid.org/" target="_blank">
                <img
                  src="/static/images/RID.png"
                  alt="RID Sponsored Credit"
                  className="img-responsive"
                />
              </a>
            </div>
          </li>
        </ul>
        <p>
          <em>
            <strong>This event is approved for RID credit only</strong>
          </em>
        </p>
      </div>
    );
  }
  if (props.credit === CREDIT_TYPES.BOTH_RID_ACET_CREDIT) {
    return (
      <div>
        <ul className="list-unstyled">
          <li className="img-link">
            <div className="card">
              <a href="https://www.rid.org/" target="_blank">
                <img
                  src="/static/images/RID.png"
                  alt="RID Sponsored Credit"
                  className="img-responsive"
                />
              </a>
            </div>
          </li>
          <li className="img-link">
            <div className="card">
              <a href="https://www.rid.org/continuing-education/acet/" target="_blank">
                <img
                  src="/static/images/ACET.png"
                  alt="ACET Sponsored Credit"
                  className="img-responsive"
                />
              </a>
            </div>
          </li>
        </ul>
        <p>
          <em>
            <strong>This event is approved for both RID and ACET credit</strong>
          </em>
        </p>
      </div>
    );
  }
  return null;
};

EventCreditTypeLabel.propTypes = {
  credit: creditApprovalPropType.isRequired,
};

const EventCreditPanel = (props) => (
  <div className="panel panel-default">
    <StrandPanelHeading title={'Event Credit'} strand={props.event.strand_type} />
    <div className="panel-body">
      <div className="row">
        {props.event.credit_approval !== CREDIT_TYPES.NO_CREDIT ? (
          <div className="col-xs-12 col-md-6 mb1">
            <EventCreditTypeLabel credit={props.event.credit_approval} />
          </div>
        ) : (
          <div className="col-xs-12 col-md-6 mb1">
            <EventCreditTypeLabel credit={props.event.credit_approval} />
            <NtidSponsorMessage
              ceu_val={props.event.ceu_val}
              ceu_type={props.event.ceu_type}
              instruction_level={props.event.instruction_level}
            />
          </div>
        )}
        <div className="col-xs-12 col-md-6">
          <strong>RIT Nondiscrimination Statement</strong>
          <RitNonDiscriminationStatement />
          <strong>Reasonable Accommodations</strong>
          <ReasonableAccommodationsStatement />
        </div>
      </div>
      {props.event.credit_approval !== CREDIT_TYPES.NO_CREDIT ? (
        <div className="mt1 col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
          <NtidSponsorMessage
            ceu_val={props.event.ceu_val}
            ceu_type={props.event.ceu_type}
            instruction_level={props.event.instruction_level}
          />
        </div>
      ) : null}
    </div>
  </div>
);

EventCreditPanel.propTypes = {
  event: eventExplorePropTypes.isRequired,
};

export default EventCreditPanel;

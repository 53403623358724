import React from 'react';
import moment from 'moment';

import { StrandPanelHeading } from 'components/shared';
import { eventExplorePropTypes, userPropTypes } from 'helpers/proptypes';

const EventRidInfoPanel = ({ event, user }) =>
  user.isAdmin &&
  (event.ceu_val || event.ceu_type) && (
    <div className="panel panel-default">
      <StrandPanelHeading
        title={
          <span>
            RID Info <i className="fa fa-lock" />
          </span>
        }
        strand={event.strand_type}
      />
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>RID Activity Number</strong>
              <p className="info-description">{event.rid_activity_number || '-'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>Workshop ID</strong>
              <p className="info-description">{event.workshop_id || '-'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>RID Submitted</strong>
              <p className="info-description">
                {event.rid_activity_number_created ? (
                  `${moment(event.rid_activity_number_created).format('MMM D, YYYY')}`.trim()
                ) : (
                  <span>Event has not been submitted to RID</span>
                )}
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>RID Event Location</strong>
              <p className="info-description">{event.location || '-'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>Facilitator Requested CEUs</strong>
              <p className="info-description">{event.facilitator_requesting_ceus ? 'Yes' : 'No'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>RID Activity Number (Facilitator)</strong>
              <p className="info-description">{event.facilitator_rid_activity_number || '-'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>Workshop ID (Facilitator)</strong>
              <p className="info-description">{event.facilitator_workshop_id || '-'}</p>
            </div>
          </div>

          <div className="col-xs-12 col-md-6 mb1">
            <div className="info-description">
              <strong>RID Facilitator Submitted</strong>
              <p className="info-description">
                {event.facilitator_rid_activity_number_created ? (
                  `${moment(event.facilitator_rid_activity_number_created).format(
                    'MMM D, YYYY'
                  )}`.trim()
                ) : (
                  <span>Facilitator version of this event has not been submitted to RID</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-footer">
        <p>
          <i className="fa fa-lock" /> Only admins can see this information
        </p>
      </div>
    </div>
  );

EventRidInfoPanel.propTypes = {
  event: eventExplorePropTypes.isRequired,
  user: userPropTypes.isRequired,
};

export default EventRidInfoPanel;

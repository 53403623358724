import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PageHeader } from 'components';
import { EventDictionary, EventLabels } from 'helpers/dictionaries';
import { eventExplorePropTypes, userPropTypes } from 'helpers/proptypes';

import { eventRegistrationStatus } from '../EventRegistrationStatus';
import EventStatus from './EventStatus';
import UserEventActions from './UserEventActions';
import EventCallouts from './EventCallouts';

const { REG_STATUS } = EventDictionary;
const { EVENT_TYPES } = EventLabels;

const UserEventSummary = ({ event, user, registrationOpen }) => {
  if (event.canceled) {
    return (
      <span>
        <strong>
          This event has been <span className="text-danger">canceled</span>.
        </strong>{' '}
        {event.cancelation_reason
          ? `Reason: ${event.cancelation_reason}`
          : 'No reason was specified.'}
      </span>
    );
  }

  const isRegistered =
    !!event.registration_status && event.registration_status !== REG_STATUS.IN_CART;

  if (isRegistered) {
    const status = eventRegistrationStatus(event.registration_status);
    return <strong>{status.message}</strong>;
  }

  if (registrationOpen && !event.private) {
    const inCart = user.shoppingcart.events.includes(event.pk);
    if (inCart) {
      return <strong>This event is in your cart.</strong>;
    }
  }

  return (
    <span>
      You have <strong>not</strong> registered for this event.
    </span>
  );
};

UserEventSummary.propTypes = {
  user: userPropTypes.isRequired,
  event: eventExplorePropTypes.isRequired,
  registrationOpen: PropTypes.bool.isRequired,
};

const EventHeader = ({
  event,
  user,
  registrationOpen,
  addDropOpen,
  onAddCartClick,
  onRemoveCartClick,
  onRegistrationRequestClick,
}) => {
  return (
    <div className="white-soft-box2 white-soft-box2--no-animate" style={{ paddingBottom: '16px' }}>
      <PageHeader.Wrapper>
        <PageHeader.Title>{event.title}</PageHeader.Title>
        <PageHeader.Subtext>
          <EventCallouts {...event} />
          <EventStatus firstDate={moment(event.first)} lastDate={moment(event.last)} />
          &nbsp;&nbsp;·&nbsp;&nbsp;
          {event.academic_year} {event.academic_season}
          &nbsp;&nbsp;·&nbsp;&nbsp;
          {EVENT_TYPES[event.type]}
        </PageHeader.Subtext>
      </PageHeader.Wrapper>
      <div
        className="flex-row flex-wrap flex-row--space-between flex-row--align-center"
        style={{ marginTop: '15px' }}
      >
        {user ? (
          <UserEventSummary event={event} user={user} registrationOpen={registrationOpen} />
        ) : null}
        <div className="flex-row flex-wrap flex-end">
          {user ? (
            <UserEventActions
              event={event}
              user={user}
              registrationOpen={registrationOpen}
              addDropOpen={addDropOpen}
              onAddCartClick={onAddCartClick}
              onRemoveCartClick={onRemoveCartClick}
              onRegistrationRequestClick={onRegistrationRequestClick}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

EventHeader.propTypes = {
  event: eventExplorePropTypes.isRequired,
  user: userPropTypes,
  registrationOpen: PropTypes.bool.isRequired,
  addDropOpen: PropTypes.bool.isRequired,
  onAddCartClick: PropTypes.func.isRequired,
  onRemoveCartClick: PropTypes.func.isRequired,
  onRegistrationRequestClick: PropTypes.func.isRequired,
};

export default EventHeader;

import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components';
import { EventDictionary } from 'helpers/dictionaries';

const { PUB_STATUS } = EventDictionary;

const DraftEventInfoAlert = (props) => {
  if (props.publicationStatus !== PUB_STATUS.DRAFT) {
    return null;
  }
  return (
    <React.Fragment>
      <Alert type="info" icon="fa-info-circle" dismissable>
        The page below is a <strong>draft</strong> of what this event's advertisement will look
        like. Only facilitators and admins can view this page.
      </Alert>
      {props.facilitatorRequestingCeus && !props.facilitatorRidNumberCreated && (
        <Alert type="info" icon="fa-info-circle" dismissable>
          A facilitator has requested CEUs for this event.
        </Alert>
      )}
    </React.Fragment>
  );
};

DraftEventInfoAlert.propTypes = {
  publicationStatus: PropTypes.oneOf(Object.values(PUB_STATUS)),
  facilitatorRequestingCeus: PropTypes.bool.isRequired,
  facilitatorRidNumberCreated: PropTypes.bool.isRequired,
};

export default DraftEventInfoAlert;

import React from 'react';
import { eventRegistrationStatusPropType } from './proptypes';
import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

const tooltips = {
  [REG_STATUS.PENDING]: {
    message: (
      <span>
        Your registration is <span className="text-info">pending manager approval</span>.
      </span>
    ),
    tooltipMessage: 'Your registration is pending <br />manager approval',
    icon: 'fa-hourglass-half',
  },
  [REG_STATUS.APPROVED_PENDING_REVIEW]: {
    message: (
      <span>
        Your registration was approved by your manager, and is{' '}
        <span className="text-info">awaiting final approval</span>.
      </span>
    ),
    tooltipMessage:
      'Your registration was approved <br />by your manager, and is <br />awaiting final approval',
    icon: 'fa-hourglass-end',
  },
  [REG_STATUS.APPROVED]: {
    message: (
      <span>
        You are <span className="text-success">registered &amp; approved</span> for this event.
      </span>
    ),
    tooltipMessage: 'You are registered & <br />approved for this event',
    icon: 'fa-check',
  },
  [REG_STATUS.DENIED]: {
    message: (
      <span>
        You were <span className="text-danger">denied</span> from this event.
      </span>
    ),
    tooltipMessage: 'You were denied <br />from this event',
    icon: 'fa-ban',
  },
  [REG_STATUS.WITHDRAW]: {
    message: (
      <span>
        You <span className="text-danger">withdrew</span> from this event.
      </span>
    ),
    tooltipMessage: 'You withdrew <br />from this event',
    icon: 'fa-ban',
  },
  [REG_STATUS.IN_CART]: {
    message: <span>This event is in your cart.</span>,
    tooltipMessage: 'This event is <br />in your cart',
    icon: 'fa-shopping-cart',
  },
  [REG_STATUS.FACILITATOR]: {
    message: (
      <span>
        You are <span className="text-info">facilitating</span> this event.
      </span>
    ),
    tooltipMessage: 'You are facilitating <br />this event',
    icon: 'fa-american-sign-language-interpreting',
  },
};

export function eventRegistrationStatus(registrationStatus) {
  return tooltips[registrationStatus];
}

const EventRegistrationStatus = (props) => {
  const tooltip = eventRegistrationStatus(props.registrationStatus);

  if (!tooltip) {
    return null;
  }

  return (
    <div className="event-block__status" data-tip={tooltip.tooltipMessage}>
      <span className={`event-block__status-icon fa ${tooltip.icon} fa-fw`} />
    </div>
  );
};

EventRegistrationStatus.propTypes = {
  registrationStatus: eventRegistrationStatusPropType,
};

export default EventRegistrationStatus;

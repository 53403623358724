import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { eventExplorePropTypes } from 'helpers/proptypes';
import { EventLabels } from 'helpers/dictionaries';
import { StrandPanelHeading } from 'components/shared';

class EventInfoPanelDescription extends React.Component {
  static propTypes = {
    event: eventExplorePropTypes.isRequired,
    teaserOpen: PropTypes.bool,
  };

  state = {
    teaserOpen: !!this.props.teaserOpen,
  };

  componentDidUpdate(prevProps) {
    if (this.props.teaserOpen && !prevProps.teaserOpen) {
      if (!this.state.teaserOpen) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ teaserOpen: true });
      }
    }
  }

  toggleTeaser = (e) => {
    e.preventDefault();
    this.setState({ teaserOpen: !this.state.teaserOpen });
  };

  render() {
    const descriptionClasses = classNames('panel-description', {
      'panel-teaser': this.state.teaserOpen,
    });
    const chevronClasses = classNames('fa fa-lg', {
      'fa-chevron-down': !this.state.teaserOpen,
      'fa-chevron-up': this.state.teaserOpen,
    });
    let description = null;
    let toggle = null;
    if (this.props.event.description) {
      description = this.state.teaserOpen ? (
        <div className={descriptionClasses}>
          <div dangerouslySetInnerHTML={{ __html: this.props.event.description }} />
        </div>
      ) : (
        <div className={descriptionClasses}>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.event.description.substring(0, 200) + '...',
            }}
          />
        </div>
      );
      if (this.props.event.description.length > 200) {
        toggle = (
          <a href="" onClick={this.toggleTeaser}>
            <i className={chevronClasses} onClick={this.toggleTeaser} />
          </a>
        );
      }
    } else {
      description = <p>A description for this event is being drafted.</p>;
    }
    return (
      <div>
        {description}
        {toggle}
      </div>
    );
  }
}

const EventInfoPanel = ({ event, teaserOpen }) => {
  const { STRAND_TYPES } = EventLabels;
  const times = event.times.length > 0 ? event.times : [['No schedule at this time', []]];
  return (
    <div>
      <div className="panel panel-default">
        <StrandPanelHeading title={STRAND_TYPES[event.strand_type]} strand={event.strand_type} />
        <div className="panel-body">
          <div className="col-xs-12">
            {times.map((time, index) => (
              <div className="info-description col-xs-12 col-md-6 col-lg-4" key={index}>
                <strong className="info-title">
                  Meeting Schedule
                  {times.length > 1 ? ` (${index + 1})` : null}
                </strong>
                <div className="event-block__line">
                  <span className="event-block__line-icon fa fa-clock-o fa-fw" />
                  {time[0]}
                </div>
                <div className="event-block__line">
                  <span className="event-block__line-icon fa fa-map-marker fa-fw" />
                  {time[2] ? time[2] : 'No Location Assigned'}
                </div>
              </div>
            ))}
            <div className="clearfix" />
            <EventInfoPanelDescription event={event} teaserOpen={teaserOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

EventInfoPanel.propTypes = {
  event: eventExplorePropTypes.isRequired,
  teaserOpen: PropTypes.bool,
};

export default EventInfoPanel;
